
// Convert the grouping structure to one the treeview component understands
const convertGroupToFilterStructure = (groupPath, group) => ({
  groupPath,
  localization: typeof group.localization.nl === 'string'
    ? group.localization.nl
    : group.localization.nl.plural,
  children: group.subGroups
    ? Object.keys(group.subGroups).map((groupCode) => convertGroupToFilterStructure(
      `${groupPath}/${groupCode}`,
      group.subGroups[groupCode],
    ))
    : undefined,
});

// TODO get this from the actual grouping
const euInstitutionCodeToName = (code) => {
  if (code === 'EC') return 'Europese Commissie';
  if (code === 'EP') return 'Europees Parlement';
  if (code === 'EP-COM-OVERIG') return 'EP overig';
  return '???';
};

module.exports = {
  convertGroupToFilterStructure,
  euInstitutionCodeToName,
};
