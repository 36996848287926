<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  Update aan lijst(en) toevoegen
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="listAddForm"
            v-model="valid"
            v-if="data"
            valid
          >
            <v-text-field
              v-model="data.title"
              :rules="titleRules"
              label="Titel"
              required
            />

            <v-textarea
              v-model="data.description"
              label="Beschrijving"
              rows="1"
              row-height="24"
            />

            <!-- The 'v-if' waits for the 'listsActive' to be set before showing the list -->
            <v-select
              v-if="listsActive"
              label="Lijsten"
              multiple
              v-model="data.lists"
              :items="listsActive"
              item-text="title"
              item-value="id"
              deletable-chips
              small-chips
              required
              :rules="listsRules"
              no-data-text="Nog geen opgeslagen lijsten beschikbaar"
            />

            <div class="text-end mt-8 mr-n4 mb-n3">
              <v-btn
                class="mr-2"
                @click="hideDialog()"
              >
                Annuleren
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="submitForm()"
              >
                Opslaan
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'ListTimelineItemForm',

  props: {
    data: {
      type: Object,
      default() {
        return {
          document: null,
          title: null,
          description: null,
          lists: [],
        };
      },
    },
  },

  data() {
    return {
      valid: false,
      dialog: true,
      titleRules: [
        (v) => !!v || 'Geef een titel op',
      ],
      listsRules: [
        (v) => v.length > 0 || 'Selecteer minimaal 1 lijst',
      ],
      busy: false,
      disabled: false,
    };
  },

  computed: {
    ...mapGetters({
      listsActive: 'listsActive',
    }),
  },

  methods: {
    hideDialog,
    trimText(text) {
      if (text.length > Config.maxListNameLengthBeforeTrim - 1) {
        return `${text.substring(0, Config.maxListNameLengthBeforeTrim)}…`;
      }
      return text;
    },
    async submitForm() {
      if (this.$refs.listAddForm.validate()) {
        this.busy = true;
        this.disabled = true;

        const success = await this.$store.dispatch('saveListItem', this.data);
        if (success) {
          this.$store.dispatch('setMessage', {
            message: this.data.lists.length === 1 ? 'Update succesvol aan lijst toegevoegd' : 'Update succesvol aan lijsten toegevoegd',
            type: 'success',
          });
        }
        this.hideDialog();
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('getActiveLists');
  },
};
</script>
