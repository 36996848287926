import domainlify from './domainify';

export default function (url) {
  if (!url) return null;
  const domains = [
    {
      url: 'zoek.officielebekendmakingen.nl/stcrt-',
      name: 'Staatscourant',
    },
    {
      url: 'zoek.officielebekendmakingen.nl/stb-',
      name: 'Staatsblad',
    },
    {
      url: 'zoek.officielebekendmakingen.nl',
      name: 'Eerste Kamer',
    },
    {
      url: 'www.tweedekamer.nl',
      name: 'Tweede Kamer',
    },
    {
      url: 'www.rijksoverheid.nl',
      name: 'Rijksoverheid',
    },
  ];

  const foundDomain = domains.find((domain) => url.includes(domain.url));
  if (foundDomain) { return foundDomain.name; }

  return domainlify(url);
}
