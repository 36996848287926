import { mapGetters } from 'vuex';
import { checkIfUserHasRequiredModule } from './services/module_service';
import {
  parseFallbackNlLocalizationFromDocumentType,
  generateConfigForUnknownDocumentType,
} from './services/document_type_generator';

let documentTypesConfig = {};

//
// All these functions are direct copies from the polpo-shared `document_types_service.js`
// MAKE SURE TO KEEP THESE FUNCTIONS IDENTICAL WHEN CHANGES ARE MADE!
// VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

// Get the documentType by the given documentTypeId
// if it is configured simply return it.
// else generate one based on the docType.
const getDocTypeByDocTypeId = (docTypeId) => documentTypesConfig[docTypeId]
  || generateConfigForUnknownDocumentType(docTypeId);

// Get the localization of a documentType by the given docTypeId
// if it is explicitly configured simply return it.
// else estimate one based on the docType.
const getLocalizationForDocTypeId = (docTypeId, options = {}) => {
  if (!documentTypesConfig[docTypeId]) {
    return parseFallbackNlLocalizationFromDocumentType(docTypeId);
  }
  const { singular, locale = 'nl' } = options;
  const localization = documentTypesConfig[docTypeId].localization[locale]
      || documentTypesConfig[docTypeId].localization.nl;
  return typeof localization === 'string' ? localization : localization[singular ? 'singular' : 'plural'];
};

// Find documentTypes have a set of required labels.
const findDocumentTypesByLabels = (requiredLabels, includeDeprecated) => {
  if (!requiredLabels || requiredLabels.length === 0) return Object.keys(documentTypesConfig);

  const docTypes = includeDeprecated
    ? documentTypesConfig
    : Object.fromEntries(
      Object.entries(documentTypesConfig).filter(([, docTypeConfig]) => !docTypeConfig.deprecated),
    );
  return Object.entries(docTypes).reduce((foundDocTypes, [docType, docTypeConfig]) => {
    const hasAllRequiredLabels = requiredLabels.every((requiredLabel) => {
      if (Array.isArray(requiredLabel)) { // oneOfLabels needs to match one of the labels
        return requiredLabel.some((label) => docTypeConfig.labels.includes(label));
      }
      return docTypeConfig.labels.includes(requiredLabel);
    });

    if (hasAllRequiredLabels) foundDocTypes[docType] = docTypeConfig;
    return foundDocTypes;
  }, {});
};

// Check if the given modules match the documentTypes requirements.
const modulesMeetDocumentTypeRequirements = (documentTypeConfig, modules) => (
  !documentTypeConfig.requiredModules // No module requirements for docType
  || documentTypeConfig.requiredModules // OR module requirement(s) exist and are all met.
    .every((requiredModule) => checkIfUserHasRequiredModule(modules, requiredModule))
);

// Get all documentTypes that the provided modules give access to.
const getPrunedDocumentTypesByModules = (modules, { includeDeprecated }) => Object.fromEntries(
  Object.entries(documentTypesConfig)
    .filter(([, docTypeConfig]) => includeDeprecated || !docTypeConfig.deprecated)
    .filter(([, docTypeConfig]) => modulesMeetDocumentTypeRequirements(docTypeConfig, modules)),
);

/**
 * Helper function for retrieving all currently supported Europarl document (typeDocu) codes.
 * @returns {[string]} - Array of all currently supported typeDocu codes.
 */
const getAllSupportedEuroparlCodes = () => Object.keys(documentTypesConfig)
  .filter((docType) => docType.startsWith('EUROPARL-')) // Filter on all EUROPARL- documentTypes
  .map((docType) => docType.slice(9)); // Slice off the first 9 characters ('EUROPARL-') to get only the original typeDocu codes

/**
 * Helper function for retrieving all Europarl document types per institution.
 * @returns {object} Object with an array of documentType(Id)s per institution.
 */
const getAllEuroparlDocumentTypesPerInstitution = () => ({
  EC: Object.entries(documentTypesConfig)
    .filter(([, docType]) => (docType.labels
      && docType.labels.includes('govBranch:European Commission')))
    .map(([docTypeId]) => docTypeId),
  EP: Object.entries(documentTypesConfig)
    .filter(([, docType]) => (docType.labels
      && docType.labels.includes('govBranch:European Parliament')))
    .map(([docTypeId]) => docTypeId),
});

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// All these functions are direct copies from the polpo-shared `document_types_service.js`
// MAKE SURE TO KEEP THESE FUNCTIONS IDENTICAL WHEN CHANGES ARE MADE!
//

// Empower these plain functions to work with reactive Vue, while
// keeping them identical in format to their backend functions
export default {
  data() {
    return {
      documentTypesMixinReady: false,
    };
  },

  computed: {
    ...mapGetters({
      userFilters: 'userFilters',
    }),
  },

  mounted() {
    if (this.userFilters.documentTypesConfig) {
      ({ documentTypesConfig } = this.userFilters);
      this.documentTypesMixinReady = true;
    }
    // If it is not loaded after 5 seconds, use the fallback behaviour.
    setTimeout(() => { this.documentTypesMixinReady = true; }, 5000);
  },

  watch: {
    userFilters: {
      handler(newValue) {
        if (newValue.documentTypesConfig) {
          ({ documentTypesConfig } = newValue);
          this.documentTypesMixinReady = true;
        }
      },
      deep: true,
    },
  },

  methods: {
    getDocTypeByDocTypeId,
    getLocalizationForDocTypeId,
    findDocumentTypesByLabels,
    modulesMeetDocumentTypeRequirements,
    getPrunedDocumentTypesByModules,

    getAllSupportedEuroparlCodes,
    getAllEuroparlDocumentTypesPerInstitution,
  },
};
