export default function (fragDocu) {
  // We don't show extra labels for "full" documents
  if (fragDocu === 'FULL') return null;
  // We don't show extra labels for "voting results" documents
  if (fragDocu === 'VOT') return null;
  // Check for parts of annexes (ex: "ANN03-PAR03")
  const annexPartMatch = fragDocu.match(/ANN\d{2}-PAR\d{2}/);
  if (annexPartMatch) {
    const annex = annexPartMatch[0].match(/ANN\d{2}/)[0].replace(/ANN0*/, ' ');
    const part = annexPartMatch[0].match(/PAR\d{2}/)[0].replace(/PAR0*/, ' ');
    return `- annex ${annex} - part ${part}`;
  }
  // Check for corrections of annexes (ex: "ANN01-COR03")
  const annexCorrectionMatch = fragDocu.match(/ANN\d{2}-COR\d{2}/);
  if (annexCorrectionMatch) {
    const annex = annexCorrectionMatch[0].match(/ANN\d{2}/)[0].replace(/ANN0*/, ' ');
    const correction = annexCorrectionMatch[0].match(/COR\d{2}/)[0].replace(/COR0*/, ' ');
    return `- annex ${annex} - corrigendum ${correction}`;
  }
  // Check for annex (ex: "ANN01")
  const annexMatch = fragDocu.match(/ANN\d{2}/);
  if (annexMatch) {
    return `- annex ${annexMatch[0].replace(/ANN0*/, '')}`;
  }
  // Check for parts (ex: "PAR03")
  const partMatch = fragDocu.match(/PAR\d{2}/);
  if (partMatch) {
    return `- part ${partMatch[0].replace(/PAR0*/, '')}`;
  }
  // Check for 'xxx-yyy' - amendments xxx through yyy.
  const amendmentMatch = fragDocu.match(/\d{3}-\d{3}/);
  if (amendmentMatch) {
    return `- amendments ${amendmentMatch[0]}`;
  }
  // Check for corrections / "corrigendum" (ex: "COR01")
  const correctionMatch = fragDocu.match(/COR\d{2}/);
  if (correctionMatch) {
    return `- corrigendum ${correctionMatch[0].replace(/COR0*/, '')}`;
  }
  // Check for errata (ex: "ERR01")
  const erratumMatch = fragDocu.match(/ERR\d{2}/);
  if (erratumMatch) {
    return `- erratum ${erratumMatch[0].replace(/ERR0*/, '')}`;
  }
  // If none of the above was matched, return null.
  return null;
}
